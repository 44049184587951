
<template>
   <div class="padding-card-c grey">
    <v-row>
      <v-select
            v-model="planId"
            :items="maintenplans"
            item-text="concept"
            item-value="id"
            label="Plan de Pagos"
          ></v-select>
    </v-row>
    <div>
    <div style="display: flex">
      <v-subheader class="title-user-scr-b">{{ customerName }}</v-subheader>    
    </div>
    <div v-if="planId !== null" style="display: flex">
      <v-subheader class="title-user-scr-b" style="font-size: medium;">Plan: {{ planSelected[0].description }}</v-subheader>
      <v-spacer></v-spacer>
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th id="lote-1" class="text-left title-table-col">#Id</th>
          <th id="lote-1" class="text-left title-table-col">Concepto</th>
          <th id="lote-2" class="text-left title-table-col">Importe</th>
          <th id="lote-3" class="text-left title-table-col">Fecha de vencimiento</th>
        <!--  <th id="lote-4" class="text-left title-table-col">Recargo</th>
          <th id="lote-5" class="text-left title-table-col">Condonar recargo</th> -->
          <th id="lote-6" class="text-left title-table-col">Estatus</th>
        </tr>
      </thead>
      <tbody style="font-size: medium">
        <tr v-for="(textField, index) in paymentsmainten" :key="index" class="text-fields-row">
          <td style="width: 5%">{{ textField.id }} </td>
          <td style="width: 20%">
            <span>{{ textField.concept }}</span>
          </td>
          <td style="width: 15%">
            <span>{{ $currency.format(textField.amount) }}</span>
          </td>
          <td style="width: 15%">
            {{ textField.paymentDueDate }}
          </td>
       <!--   <td v-if="textField.id" style="width: 15%">
            {{ $currency.format(textField.surcharges) }}
          </td>
          <td v-else>-</td>
          <td v-if="textField.id" style="width: 10%; padding-left: 35px">
            <v-checkbox
              :disabled="textField.surcharges == 0 || textField.status === 'paid'"
              v-model="textField.forgiveSurcharges"
              @click="alertcondone(textField)"
            />
          </td>
          <td v-else>-</td> -->
          <td v-if="textField.id" style="width: 15%">
            {{ textField.status === 'paid' ? 'Pagado' : textField.status === 'pending' ? 'Pendiente' : textField.status }}
          </td>
          <td v-else>No pagado</td>

          <td v-if="textField.id" style="width: 5%">
            <ModalFeePayment :payment="textField" @payFee="alertregisterpayment" :key="textField.id" ></ModalFeePayment>        
          </td>
          <td v-else>-</td>
          <td v-if="textField.id && textField.status === 'paid'" style="width: 5%">
            <v-btn @click="downloadPDF(textField)">
              <v-icon>mdi-download</v-icon>
            </v-btn>  
          </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>

    
  </div>
</template>



<script>
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import ModalFeePayment from '../../components/ModalFeePayment.vue';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');


export default {
  props: {
    customerId: {
      type:  [Number, String],
      required: true,
    },
    customerName: {
      type: String,
      required: true
    },
    lote: {
      type: String,
      required: true
    }
  },
  components: { ModalFeePayment },
  data() {
    return {
      paymentsmainten: [],
      planId: null,
      planSelected: null,
      maintenplans: [], 
      loading: false,
      validForGenerateModelPayment: true,
      dayjs,
      textFields: [],
      items: ['pending', 'paid', 'canceled'],
      initialConcept: '',
      initialPayment: '',
      initialDate: dayjs(Date.now()).format('YYYY-MM-DD'),
      initialPayments: [],
      optionalPayments: [],
      modelPymentData: {
        startYear: dayjs(Date.now()).format('YYYY'),
        startMonth: dayjs(Date.now()).format('MM'),
        paymentDueDay: '05',
        amount: '0',
        numberMonthlyPayments: '0',
      },
    };
  },
  mounted() {   
    this.fetchPlans();
  },
  computed: {
    existPayments() {
      return this.optionalPayments.some((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
    },
  },
  methods: {
    actionsPay(item, action) {
      this.loading = true;
      console.log(item);
      adminScheduleMaintenanceService[action](item.id, item)
        .then(() => {
          this.fetch();
          let message = '';
          if (action === 'payByIdPayment') {
            message = 'Se ha registrado el pago correctamente';
          }
          if (action === 'cancelSurchargesByIdPayment') {
            message = 'Se condonaron los recargos del mes seleccionado';
          }
          this.$swal('Operación realizada', message, 'success');
          this.loading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status >= 400) {
            this.$swal('Error al actualizar configuraciones de recargo', error.response.data.message, 'warning');
          }
        });
    },

    fetch() {           
      if (this.planId !== null ) {
        this.planSelected = this.maintenplans.filter( p => p.id === this.planId);
        console.log(this.planSelected);
        adminScheduleMaintenanceService.fetchUserPlan(this.customerId, this.planId).then((response) => {        
          this.paymentsmainten = response.data.sort((a, b) => new Date(a.paymentDueDate) - new Date(b.paymentDueDate));          
        });
      }
    },
    fetchPlans() {
      adminScheduleMaintenanceService.fetchUserSuscriptions(this.customerId).then((response) => {         
        this.maintenplans = response.data;
        this.planId = this.maintenplans[0].id;
        this.fetch();
      });
    },
    alertcondone(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al condonar el pago de recargo este será $0 y no habrá forma de reajustarlo',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡condonar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'cancelSurchargesByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se condonaron los recargos del mes seleccionado', 'error');
        }
      });
    },
    alertregisterpayment(payment) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al registrar un pago no habrá forma de cambiar el estatus nuevamente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡registrar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log(payment);
          this.actionsPay(payment, 'payByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se registró el pago del usuario', 'error');
        }
      });
    },
    async downloadPDF(payment) {     
      adminScheduleMaintenanceService.receiptPdf(this.customerName, this.lote, payment.amount/100, payment.paymentDate, 
        payment.concept
      )
      .then( response => {
       // console.log(response);        
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL); // Opens the PDF in a new tab
      })
      .catch (error => {
        console.error("Error downloading PDF:", error);
    })
    },
  },
  watch: {
     planId(newPlan){    
      adminScheduleMaintenanceService.fetchUserPlan(this.customerId, newPlan).then((response) => {        
        this.paymentsmainten = response.data.sort((a, b) => new Date(a.paymentDueDate) - new Date(b.paymentDueDate));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, 
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
 .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  font-size: medium;
}
</style>