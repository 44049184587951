var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","height":"30px","width":"30px"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-multiple-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"padding-card"},[_c('p',{staticClass:"title-modal"},[_vm._v("Usuarios suscritos")]),_c('v-form',{ref:"formSuscribed",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.checkbox,"rules":_vm.rules.userRequired,"multiple":"","item-text":_vm.username,"item-value":"id","label":"Usuarios","items":_vm.usersAllow},model:{value:(_vm.userIds),callback:function ($$v) {_vm.userIds=$$v},expression:"userIds"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-checkbox',{attrs:{"disabled":!_vm.usersAllow.length,"label":"Suscribir a todos"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1)],1),_c('v-row',_vm._l((_vm.months),function(month,index){return _c('v-col',{key:index,staticClass:"ma-0 pa-0",attrs:{"cols":"2","sm":"3"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"label":month.name,"value":month.value},model:{value:(_vm.selectedMonths),callback:function ($$v) {_vm.selectedMonths=$$v},expression:"selectedMonths"}})],1)}),1),_c('v-btn',{attrs:{"depressed":"","color":"#00aff2","dark":""},on:{"click":_vm.suscribe}},[_vm._v(" Suscribir ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
        { text: 'Nombre', value: 'username' },
        { text: 'Usuario', value: 'usernameLogin' },
        { text: 'Email', value: 'email' },
        { text: 'Lote', value: 'lotPurchased' },
        { text: 'Acciones', value: 'actions', sortable: false } ],"items":_vm.usersSuscribed,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.unsuscribe(item.id, _vm.maintenanceFeeId)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-remove")]),_vm._v(" Unsubscribe ")],1)]}}])}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }