<template>
  <!-- App.vue -->

  <v-app>
    <v-navigation-drawer app v-model="drawer" absolute temporary color="primary">
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-img max-width="175" src="@/assets/logo.png"></v-img>
          <v-divider></v-divider>

          <v-list-item
            style="margin-top: 25px"
            v-for="(leftnavbar, i) in leftnavbar"
            :key="i"
            :to="{ name: leftnavbar.name }"
          >
            <v-list-item-icon>
              <v-icon color="white" v-text="leftnavbar.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text" v-html="leftnavbar.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn large @click="logout" rounded block>
            <v-icon left> mdi-logout-variant </v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon dark @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title><v-img max-width="150" src="@/assets/logo.png"></v-img></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn dark x-large icon :to="{ name: 'notificationsUser' }">
        <v-badge :content="messages" :value="messages" color="red" overlap>
          <v-icon large> mdi-bell-circle </v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <div style="margin: 25px 10px 75px 10px">
          <!-- If using vue-router -->
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>

    <v-footer app>
      <v-bottom-navigation fixed grow background-color="primary" color="white" v-model="value">
        <div v-for="(bottomnav, i) in bottomnav" :key="i">
          <v-btn fab x-large icon link dark :to="{ name: bottomnav.name }" style="padding: 12px 12px 12px 12px">
            <v-icon v-text="bottomnav.icon"></v-icon>
          </v-btn>
        </div>
      </v-bottom-navigation>
    </v-footer>
  </v-app>
</template>

<script>
import notificationService from '@/services/notifications';

export default {
  data() {
    return {
    bottomnav: [
      {
        icon: 'mdi-home-circle',
        name: 'dashboardUser',
      },
     {
        icon: 'mdi-account-cash',
        name: 'financial',
      },
      {
        icon: 'mdi-clock-check',
        name: 'bookingUser',
      }, 
      {
        icon: 'mdi-account-circle',
        name: 'profile',
      },
    ],
    leftnavbar: [
    /*  {
        name: 'debtors',
        title: 'Lista de deudores',
        icon: 'mdi-account-clock',
      }, 
      {
        name: 'cardsuser',
        title: 'Tarjetas domiciliadas',
        icon: 'mdi-credit-card-check-outline',
      }, */
      {
        name: 'events',
        title: 'Próximos eventos',
        icon: 'mdi-calendar-month-outline',
      },  
      {
        name: 'regulations',
        title: 'Reglamento',
        icon: 'mdi-script-text-outline',
      },
      {
        name: 'mytickets',
        title: 'Tickets',
        icon: 'mdi-ticket',
      },
    ],
    value: 'recent',
    drawer: false,
    group: null,
  }
  },
  computed: {
    messages() {
      return this.$store.state.messages;
    },
  },
  created () {
    this.getNotifications();
  },
  methods: {
    logout() {
      this.$store.dispatch('session/logout');
    },
    getNotifications() {
      notificationService.fetch().then((response) => {
        this.$store.commit('resetMessages');
        response.data.forEach(item => {
          if (!item.readed && item.recipientId) {
            this.$store.commit('messages');
          }
        });
      });
    },
  },
};
</script>