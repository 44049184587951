<template>
  <div style="width: 100%;">  
    <h3 style="color:#787878" v-if="plan !== undefined">{{ plan.concept }}</h3>  
    <h5 style="color:#787878">Reporte emitido por App Dzidzil-há</h5>   
    <div class="table-wrapper">
      <div class="scroll-container">
        <v-simple-table fixed-header class="scroll-table" v-if="plan !== undefined" style="margin:30px 5px; font-size:12px">          
          <thead>
            <tr>
              <th class="sticky-column first-column">Lote</th>
              <th class="sticky-column second-column">Nombre</th>
              <th v-for="month in months" :key="month" class="text-left">{{ month }}</th>
              <th class="text-left">Total Pagado</th> <!-- New Column -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userTotal, index) in totalPerUser" :key="index">
              <td class="sticky-column first-column" style="width: 5%">{{ userTotal.usernameLogin }}</td>
              <td class="sticky-column second-column" style="width: 40%">{{ userTotal.username }}</td>
              <td v-for="month in months" :key="month" style="width: 5%">
                <template v-if="payments[userTotal.userId] && payments[userTotal.userId][month]">
                  <span v-if="payments[userTotal.userId][month].status === 'paid'">
                    <v-icon style="color:green; font-size:12px">{{ $currency.format(payments[userTotal.userId][month].amount) }}</v-icon>
                  </span>
                  <span v-if="payments[userTotal.userId][month].status === 'pending'">
                    <v-icon style="color:red; font-size:12px">{{ $currency.format(payments[userTotal.userId][month].amount) }}</v-icon>
                  </span>
                </template>
              </td>
              <td style="width: 10%" class="paid-currency">{{ $currency.format(userTotal.totalPaid) }}</td> <!-- Display Total Paid per User -->
            </tr>
          </tbody>    
          <tfoot>
            <tr>
              <td class="sticky-column first-column"></td>
              <td class="sticky-column second-column"><strong>Total Mensual</strong></td>
              <td v-for="month in months" :key="'total-' + month" style="width: 5%">
                <span class="paid-currency">{{ $currency.format(getTotalPerMonth(month, 'paid')) }}</span>
              </td>
              <td class="paid-currency">{{ $currency.format(getTotalOverall('paid')) }}</td> <!-- Total Overall Paid -->
            </tr>      
          </tfoot>      
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payments: {
      type: Object,
      default: () => ({}),
    },
    plan: {
      type: Object,
    }
  },
  data() {
    return {
      months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    };
  },
  computed: {
    totalPerUser() {
      return Object.entries(this.payments).map(([userId, userData]) => {
        const totalPaid = Object.keys(userData)
          .filter(key => key !== "user") // Exclude "user" key
          .reduce((sum, month) => {
            return userData[month]?.status === "paid" ? sum + (userData[month]?.amount || 0) : sum;
          }, 0);

        return {
          userId,
          usernameLogin: userData.user.usernameLogin,
          username: userData.user.username,
          totalPaid
        };
      });
    }
  },
  methods: {
    getTotalPerMonth(month, status) {     
      return Object.values(this.payments).reduce((total, item) => {
        if (item[month] && item[month].status === status) {
          return total + (item[month].amount || 0);
        }
        return total;
      }, 0);
    },
    getTotalOverall(status) {
      return this.months.reduce((total, month) => {
        return total + this.getTotalPerMonth(month, status);
      }, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
.table-wrapper {
  width: 100%;
  overflow: hidden;
}

/* Scrollable container */
.scroll-container {
  overflow-x: auto;  /* Enables horizontal scrolling */
  overflow-y: auto;  /* Enables vertical scrolling */
  max-height: 500px; /* Adjust table height */
  border: 1px solid #ddd; /* Optional: Border to define table area */
}

/* Ensure table is wide enough to scroll */
.scroll-table {
//  min-width: 90%; /* Adjust based on column count */
  width: 100%;
}

/* Sticky header */
.v-simple-table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

/* Fix first and second columns */
.sticky-column {
  position: sticky;
  left: 0;
  background: white;
  z-index: 3;
}

/* Adjust positions */
.first-column {
  left: 0;
  min-width: 80px;
  max-width: 100px;
}

.second-column {
  left: 80px;
  min-width: 200px;
  max-width: 250px;
}

/* Borders for better UI */
.sticky-column::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ddd;
}

/* Scrollbar styling (optional) */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
