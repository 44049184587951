
<template>  
   <div class="padding-card-c grey">
    <v-card class="padding-card">
      <v-row>
        <v-select
              v-model="planId"
              :items="paymentPlans"
              item-text="concept"
              item-value="id"
              label="Plan de Pagos"
            ></v-select>
      </v-row>

      <v-row style="margin-bottom: 30px">
               
        <v-btn 
            depressed 
            style="margin-left: 10px;"
            color="green"
            @click="exportToCsv"
          >Exportar CSV</v-btn>
          <v-btn 
            depressed 
            style="margin-left: 10px;"
            @click="goToCancel"
            >Cancelar</v-btn>
        </v-row>
    
    <!--  <div v-if="planId" >
        <button-download :progress="progress" @generateReport="downloadPdf()"  />
      </div> -->

      <vue-html2pdf
          :show-layout="contentRendered"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="cuotas_mant"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="letter"
          pdf-orientation="portrait"
          @progress="onProgress($event)"
          @startPagination="startPagination()"
          @hasPaginated="hasPaginated()"
          ref="html2Pdf"
      >
                
        <cuotas-report 
          v-if="planId" 
          :payments="payments" 
          :plan="planSelected"
          @domRendered="domRendered()" 
          slot="pdf-content"/> 

      </vue-html2pdf>

     
    </v-card>
  </div>
</template>



<script>

import MaintenancePayService from '@/services/admin/maintenancePayments';
import VueHtml2pdf from 'vue-html2pdf';
import CuotasReport from '@/components/reports/CuotasReport';
// import ButtonDownload from '@/components/reports/ButtonDownload';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components:{
    VueHtml2pdf,
    CuotasReport,
 //   ButtonDownload
  },
  data: () => ({
    page: 1,
    payments: {},
    paymentPlans: [],
    tosearch: null,
    planId: null,
    planSelected: null,
    contentRendered: false,
    progress: 0,
    generatingPdf: false,
    pdfDownloaded: false,
    fields:['Lote', 'Nombre', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    months:['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
  }),
  created() {
    this.getPaymentPlans();
  },
  computed: {
    userImageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.payments.length / 10);
    },

    shownUsersCards() {
      const { page, users } = this;
      const number = 10; // Numero de elementos a mostrar por página
      return users.slice((page - 1) * number, page * number);
    },
  },
  methods: {
    getPayments(id) {
      MaintenancePayService.fetchByPlan(id).then((response) => {
        this.payments = response.data;
        console.log(this.payments);
      });
    },
    getPaymentPlans() {
      MaintenancePayService.fetch().then((response) => {
        this.paymentPlans = response.data;
      });
    },
     onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`)
    },
    startPagination() {
      console.log(`PDF has started pagination`)
    },
    hasPaginated () {
      console.log(`PDF has been paginated`)
    },
    
    async downloadPdf() {
      var opt = {
            pagebreak: {
                mode: ['avoid-all', 'css', 'legacy']
            }
        };
      this.$refs.html2Pdf.generatePdf(opt);
    },
    
    goToCancel() {
      // Redirect to a specific route
      this.$router.push('/'); // Replace '/desired-path' with your actual route path
    },
    exportToCsv() {
        if (!this.payments || this.payments.length === 0) {
          alert('No data available for export.');
          return;
        }

        // Prepare CSV data
        const headers = this.fields.join(','); // Convert fields to CSV header row
        const rows = Object.values(this.payments).flatMap(row => {
          return [
            `"${row.user.usernameLogin}"`,
            `"${row.user.username}"`,
            ...this.months.map(field => `"${row[field] ? row[field].status ==='paid' ? row[field].amount : 0 : ''}"`)
          ].join(',');
        });
        const csvContent = [headers, ...rows].join('\n');

        // Create a Blob and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', this.planSelected.concept+'.csv');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    
    },
    watch:{
      planId(newValue) {
        this.planSelected = this.paymentPlans.find((plan)=>plan.id == newValue);
        console.log(this.planSelected);
        this.getPayments(newValue);
      }
    }
  };
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>