<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" v-bind="attrs" v-on="on">
          <v-icon>mdi-cash-check</v-icon>
        </v-btn>       
      </template> 
        
        <v-card>                      
            <div class="padding-card">
                <h3>{{ payment.concept }}</h3>
                <h5>Fecha de vencimiento:  {{ payment.paymentDueDate}} </h5>
             
                <v-form ref="formPayFee" lazy-validation class="ma-5 pt-5">  
                    <label for="date"
                        ><DatePicker                                                              
                        label="Fecha de pago"                          
                        name="date"
                        @change="paymentDate = $event"                           
                    /></label>
                    <v-text-field 
                        type="number"  
                        prefix="$" 
                        suffix="MXN" 
                        label="Importe a pagar" 
                        v-model="amount"></v-text-field>
                   
                    <v-container
                        class="px-0"
                        fluid
                        >                   
                        <v-btn  
                          depressed 
                          color="#00aff2" 
                          @click="payFee"
                          dark >
                            Pagar</v-btn>
                    </v-container>
                </v-form>                
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  props: {
    payment: {
      type: Object,
      default: () => ({}),
    }
  }, 
  components: {
    DatePicker,
  },  
  data() { 
    return {
      dialog: false,
      amount: 0,
      paymentDate: dayjs(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
      localPayment: {
        id:null,
        amount: 0,
        paymentDate: new Date()
      }
    }
  },
  mounted() {   
    this.amount = (this.payment.amount) / 100;
    this.localPayment.paymentDate = this.paymentDate;
    this.localPayment.id = this.payment.id;
  },
  methods:{
    payFee() {
      this.localPayment.paymentDate = dayjs(this.paymentDate).format('YYYY-MM-DD');
      this.localPayment.amount = this.amount * 100;
      this.$emit('payFee', this.localPayment);
    },
   
  }, 
  watch:{
    paymentDate(newvalue){
        console.log(newvalue);
    }
  } 
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>