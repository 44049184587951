<template>
  <v-app>
    <div class="hero">
      <div class="center-h-v">
        <v-img max-width="300" src="@/assets/logo.png"></v-img>
        <v-card max-width="500" style="border-radius: 25px">
          <div class="padding-card">
            <v-card-title style="font-size: 19px !important; font-weight: 600">Bienvenido a Dzidzil-Há</v-card-title>

            <v-card-subtitle>Inicia sesión para continuar</v-card-subtitle>
            <form>
              <v-text-field
                v-model="email"
                :rules="[rules.required]"
                label="Usuario"
                aria-autocomplete="off"
                autofocus
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show3 ? 'text' : 'password'"
                name="input-10-2"
                label="Contraseña"
                @keypress.enter="login"
                @click:append="show3 = !show3"
              ></v-text-field>
              <v-btn block @click="login" color="#00aff2" rounded x-large dark> Ingresar </v-btn>
              <div class="v-messages error--text text-center" style="width:100%" v-if="alert">Usuario o contraseña incorrectos</div>              
            </form>
          </div>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.hero {
  background: url('../assets/login.jpg');
  background-size: cover;
  height: 100vh;
}
</style>

<script>
import authService from '../services/auth';
import serviceGeneral from '@/services/users';

export default {
  components: {
    
  },
  data() {
    return {
      loggingIn: false,
      alert: false,
      show3: false,
      email: '',
      password: '',
      checkbox: '',
      rules: {
        required: (value) => !!value || 'Información requerida.',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Correo electrónico incompleto o inválido.';
        },
        min: (value) => value.length >= 8 || 'Contraseña debe ser de lamenos 8 caracteres.',
      },
    };
  },
  methods: {
    fetchSettings() {
      serviceGeneral.fetchSettings().then((response) => {
        this.$store.commit('settings', response.data);
      });
    },
    login() {
      this.loggingIn = true;
      authService
        .login(this.email, this.password)
        .then((response) => {
          const { data } = response;
          this.loggingIn = false;
          const user = {
            usernameLogin: data.data.name,
            email: this.email,
            created: data.data.createdAt,
            role: data.data.role,
          };
          this.$store.commit('session/ADD_USER', user);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token-bIbx0cXd', response.headers['authorization']);
          if (user.role === 'admin') {
            this.$router.push({ name: 'dashboardAdmin' });
          } else {
            this.$router.push({ name: 'dashboardUser' });
            this.fetchSettings();
          }
        })
        .catch((err) => {
          this.loggingIn = false;
          if (err.response) {
            if (err.response.status === 400 || err.response.status === 401) {
              this.alert = true;
            }
          }
        });
    },
  },
};
</script>


<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>